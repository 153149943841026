:root {
  --plyr-color-main: #c5c5c5;
  --plyr-audio-control-color: #121212;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  background: #f9f9f9;
}

.App.white-bg {
  background: #fff !important;
}

.video {
  position: fixed;
  z-index: 10;
}

.image {
  position: fixed;
  z-index: 11;
}

.video.mobile {
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
}

.image.mobile {
  bottom: 0;
  left: 0;
  width: 100vw;
  max-height: 60vh;
  object-fit: contain;
}

/* Desktop */

.video.desktop {
  top: 0;
  left: 0;
  width: 100vw;
  max-height: 100vh;
}

.image.desktop {
  bottom: -35px;
  left: 0;
  width: 100vw;
  max-height: 70vh;
  object-fit: contain;
}

/* Media Queries */
.desktop {
  display: none;
}
.mobile {
  display: unset;
}

@media (min-width: 768px) {
  .desktop {
    display: unset;
  }
  .mobile {
    display: none;
  }
}

/* Custom */
.invisible {
  opacity: 0;
}

/* Animation */

.fade {
  opacity: 0;
  transition: opacity 4s;
}

.fade.in {
  opacity: 1;
}

.fade.out {
  opacity: 0;
}

.homepage {
  height: 100vh;
  overflow: hidden;
}
