@import url("https://cdn.plyr.io/3.7.8/plyr.css");

@font-face {
  font-family: "Linear Beam";
  src: local(Linebeam), url("fonts/Linebeam.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  font-family: "Linear Beam";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
